import { dateDiff } from '../plugins/dateDiff';

export const columns = [
  {
    label: 'date',
    field: 'updatedAt',
    width: '90%',
    formatRowValue: true,
    formatFn: (value) => {
      return dateDiff(value.createdAt, value.updatedAt);
    },
    filterOptions: {
      enabled: false,
      date: true,
      placeholder: ' ',
      filterValue: '',
    },
  },
  {
    label: '',
    field: 'actions',
    width: '10%',
    sortable: false,
    actions: [
      {
        type: 'view',
        label: '',
        class: 'fa fa-eye',
        action: {
          type: 'route',
          paramsResolver: (data) => {
            return {
              'storeHistoryId': data.id,
            }
          },
          nameResolver: () => {
            return 'ViewStoreHistory';
          }
        },
      },
    ]
  },
]
